import dependencyReady from '@/client/js/utils/dependencyReady';
/**
 * Register the Comscore plugin to be integrated with the player.
 *
 * @param {object} player The JW Player instance.
 * @param {object} config Any plugin config options.
 */
function jwComscore(player, config) {
    player.on('ready', function () {
        var _a, _b;
        /**
         * Create the Streaming Tag implementation.
         * References:
         * https://alleyinteractive.atlassian.net/browse/NYP-16825
         * https://alleyinteractive.atlassian.net/browse/NYP-18264
         *
         * The cs_ucfr page tag is the consent value. It is hard-coded to '1' because Comscore
         * will only load if C0024 "Analytics Cookies and Similar Technologies" is present.
         */
        var labelMap = [
            "c3=\"".concat(config.c3, "\""),
            'c4="*null"',
            'c6="*null"',
            "ns_st_st=\"".concat(config.publisher, "\""),
            "ns_st_pu=\"".concat(config.publisher, "\""),
            'ns_st_sn="*null"',
            'ns_st_en="*null"',
            'ns_st_ge="*null"',
            'ns_st_ti="*null"',
            'ns_st_ia="*null"',
            'ns_st_ce="*null"',
            'ns_st_ddt="*null"',
            'ns_st_tdt="*null"',
        ];
        /**
         * Add additional tags if Aqfer nyp_id exists in local storage.
         * The cs_fpit value of 'c' indicates that cs_fpid is a first party cookie.
         */
        if (localStorage === null || localStorage === void 0 ? void 0 : localStorage.nyp_id) {
            labelMap.push("cs_fpid=\"".concat(localStorage.nyp_id, "\""));
            labelMap.push('cs_fpit="c"');
            labelMap.push('cs_fpdm="*null"');
            labelMap.push('cs_fpdt="*null"');
        }
        // eslint-disable-next-line no-underscore-dangle
        var streamingTag = function () { return window.ns_.StreamingAnalytics.JWPlayer(player, {
            publisherId: '6390601',
            labelmapping: labelMap.toString(),
        }); };
        if (typeof ((_b = (_a = window === null || window === void 0 ? void 0 : window.ns_) === null || _a === void 0 ? void 0 : _a.StreamingAnalytics) === null || _b === void 0 ? void 0 : _b.JWPlayer) === 'function') { // eslint-disable-line no-underscore-dangle
            // Case 1: The script is already loaded and the function
            // exists, immediately trigger the streamingTag.
            streamingTag();
        }
        else {
            // Case 2: The script is already loaded, but the function
            // doesn't exist yet. Wait for it to be ready.
            dependencyReady.waitFor('ns_', streamingTag());
        }
    });
}
var registerPlugin = window.jwplayerPluginJsonp || window.jwplayer().registerPlugin;
// Register the plugin.
registerPlugin('jwcomscore.bundle.min', '8.0', jwComscore);
